import React from 'react';

import './OKonkursie.scss';

import ikona1 from '../assets/ikona-1-1.svg';
import ikona2 from '../assets/ikona-1-2.svg';
import ikona3 from '../assets/ikona-1-3.svg';

const App: React.FC = () => {
    return (
        <div className='_o-konkursie__wrapper' id='anchor-informacje'>
            <div className='_o-konkursie__title'>Informacje</div>
            <div className='_o-konkursie__stages'>
                <div className='_o-konkursie__stage'>
                    <div className='_o-konkursie__stage-icon'>assignment</div>
                    <div className='_o-konkursie__stage-title'>Zgłoszenia</div>
                    <div className='_o-konkursie__stage-text'>
                        Wsyzstkie zgłoszenia przyjmowane są do&nbsp;31.03.2020 r.
                    </div>
                </div>

                <div className='_o-konkursie__stage'>
                    <div className='_o-konkursie__stage-icon'>playlist_add_check</div>
                    <div className='_o-konkursie__stage-title'>Kryteria projektów</div>
                    <div className='_o-konkursie__stage-text'>
                        Minimalna wartość oferty produktów  Schneider Electric w&nbsp;cenach 
                        katalogowych, brana pod uwagę w&nbsp;konkursie, to 150 000 zł netto.
                    </div>
                </div>

                <div className='_o-konkursie__stage'>
                    <div className='_o-konkursie__stage-icon'>group</div>
                    <div className='_o-konkursie__stage-title'>Wyniki</div>
                    <div className='_o-konkursie__stage-text'>
                        Wyniki konkursu zostaną ogłoszone dnia&nbsp;09.09.2020 r.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default App;
