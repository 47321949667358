import React from 'react';

import './Produkty.scss';

const App: React.FC = () => {
    return (
        <div className='_produkty__wrapper'>
            <div className='_produkty__produkt'>
                <div className='_produkty__produkt-tytul'>Power Design</div>
                <div className='_produkty__produkt-link'>
                    <a href='#' className='_produkty__produkt-link-przycisk'>
                        Odwiedź stronę
                    </a>
                </div>
                <div className='_produkty__produkt-data'>
                    Start konkursu:
                    <br />
                    15.10.2019
                </div>
                <div className='_produkty__produkt-text'>
                    <b>Kategorie konkursowe:</b>
                    <br />
                    <ol>
                        <li>
                            <span>Największy wolumenowo projekt</span>
                            <br />
                            projekt o najwyższej wartości katalogowej  produktów Schneider Electric
                        </li>
                        <li>
                            <span>Projekt EcoStruxure</span>
                            <br />
                            projekt na największej ilości skomunikowanych  produktów Schneider
                            Electric
                        </li>

                        <li>
                            <span>Projekt budownictwa  mieszkaniowego</span>
                            <br />
                            projekt instalacji elektrycznej dla inwestycji  mieszkaniowej o
                            największej liczbie lokali
                        </li>
                    </ol>
                </div>
            </div>

            <div className='_produkty__produkt'>
                <div className='_produkty__produkt-tytul'>Power Build</div>
                <div className='_produkty__produkt-link'>
                    <a href='#' className='_produkty__produkt-link-przycisk'>
                        Odwiedź stronę
                    </a>
                </div>
                <div className='_produkty__produkt-data'>
                    Start konkursu:
                    <br />
                    15.01.2020
                </div>
                <div className='_produkty__produkt-text'>
                    <b>Kategorie konkursowe:</b>
                    <br />
                    <ol>
                        <li>
                            <span>Największa wycena</span>
                            <br />
                            wycena o najwyższej wartości produktów Schneider Electric w cenach
                            katalogowych dla konkretnej inwestycji
                        </li>
                        <li>
                            <span>Największa ilość wycen</span>
                            <br />
                            najwyższa ilość przesłanych wycen, wykonanych w oprogramowaniu
                            EcoStruxure Power Build
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    );
};

export default App;