import React from 'react';

import './OKonkursie.scss';

import ikona1 from '../assets/ikona-1-1.svg';
import ikona2 from '../assets/ikona-1-2.svg';
import ikona3 from '../assets/ikona-1-3.svg';

const App: React.FC = () => {
    return (
        <div className='_o-konkursie__wrapper' id='anchor-okonkursie'>
            <div className='_o-konkursie__title'>O konkursie</div>
            <div className='_o-konkursie__stages'>
                <div className='_o-konkursie__stage'>
                    <div className='_o-konkursie__stage-icon'>
                        <img
                            src={ikona1}
                            className='_o-konkursie__stage-icon-img'
                            alt='Zaprojektuj'
                        />
                    </div>
                    <div className='_o-konkursie__stage-title'>Zaprojektuj</div>
                    <div className='_o-konkursie__stage-text'>
                        instalację elektryczną, używając programu EcoStruxure Power Design
                        <br />
                        <br />
                        lub
                    </div>
                    <div className='_o-konkursie__stage-title'>Wyceń</div>
                    <div className='_o-konkursie__stage-text'>
                        rozdzielnicę elektryczną nn w EcoStruxure Power Build
                    </div>
                </div>

                <div className='_o-konkursie__stage'>
                    <div className='_o-konkursie__stage-icon'>
                        <img src={ikona2} className='_o-konkursie__stage-icon-img' alt='Zglos' />
                    </div>
                    <div className='_o-konkursie__stage-title'>Zgłoś</div>
                    <div className='_o-konkursie__stage-text'>
                        swoją pracę za pośrednictwem  poniższego formularza zgłoszeniowego
                    </div>
                </div>

                <div className='_o-konkursie__stage'>
                    <div className='_o-konkursie__stage-icon'>
                        <img src={ikona3} className='_o-konkursie__stage-icon-img' alt='Wygraj' />
                    </div>
                    <div className='_o-konkursie__stage-title'>Wyjazd na szkolenie</div>
                    <div className='_o-konkursie__stage-text'>
                        Wyjazd do Francji z Schneider Electric<br />
                        <br />
                        Hulajnogę elektryczną marki Xiaomi<br />
                        <br />
                        Powerbanka marki APC by Schneider Electric
                    </div>
                </div>
            </div>
        </div>
    );
};

export default App;
