import React from 'react';

import './Formularz.scss';

const App: React.FC = () => {
    return (
        <div className='_formularz__center'>
            <div className='_formularz__wrapper' id='anchor-rejestracja'>
                <div className='_formularz__title'>Formularz zgłoszeniowy</div>
                <div className='_formularz__pole'>
                    <div className='_formularz__pole-label'>Imie i nazwisko*</div>
                    <div className='_formularz__pole-input'>
                        <input type='text' />
                    </div>
                </div>

                <div className='_formularz__pole'>
                    <div className='_formularz__pole-label'>E-mail*</div>
                    <div className='_formularz__pole-input'>
                        <input type='text' />
                    </div>
                </div>

                <div className='_formularz__pole'>
                    <div className='_formularz__pole-label'>Nazwa inwestycji*</div>
                    <div className='_formularz__pole-input'>
                        <input type='text' />
                    </div>
                </div>

                <div className='_formularz__pole'>
                    <div className='_formularz__pole-label'>Miejsce inwestycji*</div>
                    <div className='_formularz__pole-input'>
                        <input type='text' />
                    </div>
                </div>

                <div className='_formularz__pole'>
                    <div className='_formularz__pole-label'>Nazwa inwestora*</div>
                    <div className='_formularz__pole-input'>
                        <input type='text' />
                    </div>
                </div>

                <div className='_formularz__pole'>
                    <div className='_formularz__pole-label'>Miejsce zatrudnienia</div>
                    <div className='_formularz__pole-input'>
                        <input type='text' />
                    </div>
                </div>

                <div className='_formularz__pole _wide'>
                    <div className='_formularz__pole-label'>
                        Pliki z programu
                        <br />
                        (.zip, .esp_build, .esp_designlv)
                    </div>
                    <div className='_formularz__pole-input'>
                        <div className='input-file__wrapper'>
                            <div className='input-file__button'>cloud_upload</div>
                            <div className='input-file__label'>Wybierz plik</div>
                        </div>
                        <input type='file' accept='.zip, .esp_build, .esp_designlv' />
                    </div>
                </div>

                <button className='_formularz__button'>Wyślij zgłoszenie</button>
            </div>
        </div>
    );
};

export default App;
