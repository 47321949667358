import React from 'react';

import './Nav.scss';
import logo from '../assets/logo.svg';

export function navigateTo(anchor: string) {
    const anchorElement = document.getElementById('anchor-' + anchor);

    if (anchorElement) {
        setTimeout(() => {
            window.scrollTo({ top: anchorElement.offsetTop - 60, behavior: 'smooth' });
        });
    }
}

interface NavState {
    isOpen: boolean;
}

export default class Nav extends React.Component<{}, NavState> {
    state: NavState = {
        isOpen: false
    };

    private changeState() {
        this.setState(p => ({ isOpen: !p.isOpen }));
    }

    private closeNav() {
        this.setState(p => ({ isOpen: false }));
    }

    private navigateTo(anchor: string) {
        navigateTo(anchor);
        this.closeNav();
    }

    render() {
        return (
            <div className={`nav ${this.state.isOpen ? 'is-open' : ''}`}>
                <div className='nav__logo'>
                    <img src={logo} alt='Schneider Electric' className='nav__logo-img' />
                </div>

                <div className='nav__button' onClick={() => this.changeState()}>
                    <div className='nav__button-icon'>{this.state.isOpen ? 'close' : 'menu'}</div>
                </div>

                <div className='nav__links'>
                    <div className='nav__link' onClick={() => this.navigateTo('okonkursie')}>
                        O konkursie
                    </div>
                    <div className='nav__link' onClick={() => this.navigateTo('rejestracja')}>
                        Rejestracja
                    </div>
                    <div className='nav__link' onClick={() => this.navigateTo('informacje')}>
                        Informacje
                    </div>
                    <div className='nav__link' onClick={() => this.navigateTo('regulamin')}>
                        Regulamin
                    </div>
                </div>
            </div>
        );
    }
}
