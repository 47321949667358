import React from 'react';

import './OKonkursie.scss';

const App: React.FC = () => {
    return (
        <div className='_o-konkursie__wrapper' id='anchor-regulamin'>
            <div className='_o-konkursie__title'>Regulamin</div>
            <div className='_o-konkursie__text'>
                Zapraszamy do zapoznania się ze szczegółami regulaminu.
            </div>
            <a href='./regulamin.pdf' className='_o-konkursie__link'>
                <span className='ikona'>insert_drive_file</span> Pobierz regulamin
            </a>
        </div>
    );
};

export default App;
