import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Nav from './components/Nav';
import Start from './components/Start';
import OKonkursie from './components/OKonkursie';
import Formularz from './components/Formularz';
import Informacje from './components/Informacje';
import InformacjeLista from './components/Informacje-lista';
import Regulamin from './components/Regulamin';
import Produkty from './components/Produkty';
import InformacjeIkony from './components/InformacjeIkonki';

const App: React.FC = () => {
    return (
        <Router>
            <div className='App'>
                <Nav />
                <Start />
                <OKonkursie />
                <Formularz />
                <Produkty />
                <InformacjeIkony />
                <InformacjeLista />
                <Regulamin />
            </div>
        </Router>
    );
};

export default App;
