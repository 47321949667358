import React from 'react';
import moment, { Moment } from 'moment';

import './Start.scss';

import plane from '../assets/plane.png';
import cloud1 from '../assets/cloud-1.svg';
import cloud2 from '../assets/cloud-2.svg';
import { navigateTo } from './Nav';

import computer from '../assets/monitor.svg';

interface State {
    time: Moment;
}

export default class extends React.Component<{}, State> {
    state: State = {
        time: moment()
    };

    componentDidMount() {
        setInterval(() => {
            this.setState({ time: moment() });
        }, 1000);
    }

    render() {
        const duration = moment.duration(moment('2020-03-31 23:59:59').diff(this.state.time));

        return (
            <div className='_start'>
                <div className='_start__title'>
                    <b>DO BIEGU, GOTOWI, SMART!</b>
                    <p>
                        Konkurs dla użytkowników oprogramowania
                        <br /> EcoStruxure  Power Design i&nbsp;EcoStruxure Power Build
                    </p>
                </div>

                <div className='_start__graphics'>
                    <img src={computer} alt='Computer' className='_start__graphics-computer' />
                    <div className='_start__graphics-lines'>
                        <div className='_start__graphics-line-0'></div>
                        <div className='_start__graphics-line-1'></div>
                        <div className='_start__graphics-line-2'></div>
                        <div className='_start__graphics-line-3'></div>
                        <div className='_start__graphics-line-4'></div>
                        <div className='_start__graphics-line-5'></div>
                        <div className='_start__graphics-line-6'></div>
                        <div className='_start__graphics-line-7'></div>
                        <div className='_start__graphics-line-8'></div>
                        <div className='_start__graphics-line-9'></div>
                        <div className='_start__graphics-line-10'></div>
                        <div className='_start__graphics-line-11'></div>
                    </div>
                </div>

                <div className='_start__timer'>
                    {duration.asSeconds() > 0 ? (
                        <React.Fragment>
                            Pozostało <b>{Math.floor(duration.asDays())}</b> dni{' '}
                            <b>{duration.hours()}</b> h <b>{duration.minutes()}</b> m i{' '}
                            <b>{duration.seconds()}</b> s
                        </React.Fragment>
                    ) : (
                        <React.Fragment>Przyjmowanie zgłoszeń zakończone</React.Fragment>
                    )}
                </div>

                <div className='_start__button' onClick={() => navigateTo('rejestracja')}>
                    Wyślij zgłoszenie
                </div>
            </div>
        );
    }
}
