import React from 'react';

import './Informacje.scss';

import nagroda1 from '../assets/nagroda-1.jpg';
import nagroda2 from '../assets/nagroda-2.jpg';
import nagroda3 from '../assets/nagroda-3.jpg';

const App: React.FC = () => {
    return (
        <div className='_informacje__wrapper'>
            <div className='_informacje__tiles'>
                <div className='_informacje__tile'>
                    <div className='_informacje__tile-title'>Nagroda główna</div>
                    <div className='_informacje__tile-image'>
                        <img
                            src={nagroda1}
                            alt='Wyjazd do Smart Factory'
                            className='_informacje__tile-img'
                        />
                    </div>

                    <div className='_informacje__tile-text'>
                        Zobacz na własne oczy czwartą rewolucję przemysłową! Fabryka styczników
                        TeSys w Le Vaudreuil wykorzystuje nowoczesne rozwiązania, takie jak
                        zautomatyzowane linie produkcyjne, rozszerzoną rzeczywistość, lokalne
                        centra danych i inne. Dowiedz się więcej na temat uzyskanych dzięki cyfrowej
                        transformacji oszczędności energii oraz zwiększenia efektywności produkcji.
                        Przekonasz się, dlaczego fabryka w Le Vaudreuil otrzymała nagrodę “Showcase
                        for Industry of the Future” przyznawaną przez Alliance Industrie du Futur.
                    </div>
                    <a
                        href='https://www.se.com/fr/fr/work/webmarketing/industrie/evenements-industrie/smart-factory.jsp'
                        className='_informacje__tile-button'
                    >
                        Dowiedz się więcej
                    </a>
                </div>

                <div className='_informacje__tile'>
                    <div className='_informacje__tile-title'>
                        Wyróżnienie komisji
                        <br />I Stopnia
                    </div>
                    <div className='_informacje__tile-image'>
                        <img
                            src={nagroda2}
                            alt='Wyjazd do Smart Factory'
                            className='_informacje__tile-img'
                        />
                    </div>
                    <div className='_informacje__tile-text'>
                        Ekologiczna hulajnoga elektryczna marki Xiaomi, dzięki której nie będziesz
                        już musiał(a) stać ściśnięty(-a) w&nbsp;zatłoczonym autobusie.
                    </div>
                </div>

                <div className='_informacje__tile'>
                    <div className='_informacje__tile-title'>
                        Wyróżnienie komisji
                        <br />
                        II Stopnia
                    </div>
                    <div className='_informacje__tile-image'>
                        <img
                            src={nagroda3}
                            alt='Wyjazd do Smart Factory'
                            className='_informacje__tile-img'
                        />
                    </div>
                    <div className='_informacje__tile-text'>
                        Powerbank 10 000mAh marki APC by Schneider Electric, dzięki któremu dźwięk
                        ostrzeżenia o rozładowującej się baterii nie będzie Ci juz straszny.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default App;
